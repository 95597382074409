<template>
  <v-navigation-drawer id="core-navigation-drawer" v-model="drawer" :dark="true" :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl" :src="barImage" mobile-break-point="960" app width="260" v-bind="$attrs">
    <v-divider class="mb-1" />

    <v-list dense nav>
      <v-list-item>
        <v-list-item-avatar class="align-self-center" color="white" contain>
          <v-img src="../../../../assets/rabit2.jpg" max-height="50"/>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="display-1">
            {{ userName }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider class="mb-2" />
    <v-list dense nav>
      <v-btn width="100%" class="mb-2" @click="createChat" :loading="createLoading">
        <v-icon class="mr-1">mdi-plus</v-icon>新建聊天
      </v-btn>
      <v-list-item :disabled="isChating" v-for="item in chats" :key="item.id" link :class="item.id == session.id ? 'v-list-item-avtive' : ''"
        @click="sessionClick(item)">
        <v-icon class="mr-2" size="21">mdi-message-text</v-icon>
        <v-list-item-content>
          <v-list-item-title v-show="!item.isEdit">{{ item.title }}</v-list-item-title>
          <input type="text" label="会话名称" v-model="item.title" v-show="item.isEdit" autocomplete class="chatName-input">
        </v-list-item-content>
        <v-icon class="ml-2" size="15" @click.stop="editAndConfirm(item)" v-show="item.id == session.id">{{ !item.isEdit ?
          'mdi-pencil-outline' : 'mdi-check' }}</v-icon>
        <v-icon class="ml-2" size="15" @click.stop="handelDelete(item)"
          v-show="item.id == session.id">mdi-trash-can-outline</v-icon>
      </v-list-item>
    </v-list>
    <template v-slot:append>
      <div @click="logOut">
        <base-item :item="{
          title: '退出登录',
          icon: 'mdi-logout-variant',
          to: '/',
        }" />
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import Cookies from "js-cookie";
import { saveOrUpdateGptTopic, gptTopicList, deleteGptTopic } from "@/api";
// Utilities
import {
  mapState,
} from 'vuex'

export default {
  name: 'DashboardCoreDrawer',
  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    createLoading: false,
    chats: [],
    rules: {
      chatName: value => !!value || '请输入会话名称',
    },
  }),

  computed: {
    ...mapState(['barColor', 'barImage', 'session', 'isChating']),
    userName() {
      return Cookies.get('gpt_userName')
    },
    drawer: {
      get() {
        return this.$store.state.drawer
      },
      set(val) {
        this.$store.commit('SET_DRAWER', val)
      },
    },
    computedItems() {
      return this.items.map(this.mapItem)
    },
    profile() {
      return {
        avatar: true,
        title: this.$t('avatar'),
      }
    },
  },

  methods: {
    mapItem(item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: this.$t(item.title),
      }
    },
    async createChat() {
      this.createLoading = true
      try {
        let res = await saveOrUpdateGptTopic({ title: 'New Chat' })
        this.gptTopicList('isNewSession')
        this.createLoading = false
      } catch (error) {
        this.createLoading = false
      }
    },
    async gptTopicList(v) {
      let res = await gptTopicList({})
      this.chats = res
      this.$store.dispatch('gptQaInfoList').then(() => {
        if (v == 'isNewSession') this.$store.commit('SET_SESSION', res[0])
      })
    },
    sessionClick(item) {
      this.$store.commit('SET_SESSION', item)
    },
    logOut() {
      this.$store.dispatch('lgoOutFun').then(() => {
        setTimeout(function () {
          window.location.href = '/login'
        }, 1000)
      })
    },
    async editAndConfirm(item) {
      const index = this.chats.findIndex(v => v.id === item.id)
      if (!item.isEdit) {
        this.$set(this.chats[index], 'isEdit', true)
        return
      }
      let res = await saveOrUpdateGptTopic({ id: item.id, title: item.title || 'New Chat' })
      this.gptTopicList()
      this.$set(this.chats[index], 'isEdit', false)
    },
    async handelDelete(item) {
      let res = await deleteGptTopic({ id: item.id })
      if (item.id == this.session.id) this.$store.commit('SET_SESSION', {})
      this.gptTopicList()
    }
  },
  mounted() {
    this.gptTopicList()
  }
}
</script>

<style lang="sass">
  @import '~vuetify/src/styles/tools/_rtl.sass'

  #core-navigation-drawer
    .v-list-group__header.v-list-item--active:before
      opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
  .theme--dark.v-navigation-drawer
      --tw-bg-opacity: 1
      background-color: rgb(32 33 35/var(--tw-bg-opacity))
  .v-list-item-avtive
      background-color: rgba(52,53,65,.9)
  .theme--dark.v-btn.v-btn--has-bg
      border:1px solid hsla(0,0%,100%,.2)
  .chatName-input
      outline:none
      color:#fff !important
      border:none
</style>
